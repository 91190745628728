import qs from 'qs';
import {
  InitArgs,
  LocalHistoryState,
  LocationObject,
  NavigationPayload,
  Params,
  ToLocationPayload,
} from './interfaces';
import { logger } from './services';
import { locationActions } from './store/actions';
import DataStore from './store/DataStore';
import { findAnchor, parseQuery, shouldIntercept } from './utils';
import { getDefaultPage } from './utils/helpers';

class RSINavigationHandler extends DataStore {
  constructor(initArgs: InitArgs = {}) {
    super(initArgs);

    logger.log('Module mounted');

    this.updateLocation();
    this.setOnPopStateListener();
    this.setLinkInterceptor();
    this.setInitialPageValidationListener();
  }

  public updateLocation(isReplace = false) {
    const { search, hash } = window.location;
    // eslint-disable-next-line prefer-const
    let { page, ...params }: Params = parseQuery(search);

    const fullPath = `${search}${hash}`;
    const historyState = isReplace ? LocalHistoryState.Replace : LocalHistoryState.Push;

    let pageParamInUrl = true;
    if (!page) {
      page = this.getCurrentPage();
      pageParamInUrl = false;
    }
    this.dispatch(locationActions.setLocation({
      from: this.getState().location.to,
      to: {
        fullPath,
        page,
        params,
        hash,
        historyState,
      },
    }));
    // ?page= param needs to be in URL!
    if (!pageParamInUrl) {
      const replaceTo = { ...this.getState().location.to } as LocationObject;
      replaceTo.page = page;
      this.replace(replaceTo);
    }
  }

  private setOnPopStateListener = () => {
    window.onpopstate = () => {
      logger.debug('[popStateListener] New URL via pop state: ', window.location.href);
      this.updateLocation();
    };
  };

  private setLinkInterceptor() {
    document.addEventListener('click', (e) => {
      let targetElement = e.target as Element;

      if (targetElement && targetElement.matches('a[href], a[href] *')) {
        targetElement = findAnchor(targetElement);

        if (targetElement.className.indexOf('KambiBC') === -1) {
          const href = targetElement.getAttribute('href') || '';
          const targetBlank = targetElement.getAttribute('target') === '_blank';
          const separateTabClick = e.ctrlKey || e.metaKey;
          const canNavigate = !targetBlank && !separateTabClick && shouldIntercept(href);

          if (canNavigate) {
            logger.debug('[setLinkInterceptor] Intercepting link', { href });
            e.preventDefault();
            this.navigate(href);
          }
        }
      }
    });
  }

  private setInitialPageValidationListener() {
    const unsubscribe = this.store.subscribe(() => {
      const {
        location: { to },
      } = this.getState();

      if (to && window.viewArgs?.allowedPagesList) {
        unsubscribe();

        let { page } = to;
        const isInvalidPage = RSINavigationHandler.isInvalidPage(page);

        if (isInvalidPage) {
          logger.debug('[setInitialPageValidationListener] Invalid initial page, falling back to default', { page });
          page = getDefaultPage();
          const replaceTo = { ...to };
          replaceTo.page = page;
          this.replace(replaceTo);
        }
      }
    });
  }

  public push(toLocation: ToLocationPayload) {
    this.navigate(toLocation);
  }

  public replace(toLocation: ToLocationPayload) {
    this.navigate(toLocation, true);
  }

  public navigate(toLocation: ToLocationPayload, isReplace: boolean = false) {
    logger.debug('[navigate] Starting to navigate', { toLocation, isReplace });

    let page = this.getCurrentPage();
    let params = {};
    let hash = '';

    if (typeof toLocation === 'string') {
      const location = toLocation.startsWith('./') ? toLocation.substring(2) : toLocation;

      if (location.indexOf('#') > -1) {
        const locationArray = location.split('#');
        const { page: pageParam, ...rest } = parseQuery(locationArray[0]);

        page = pageParam as string || page;
        params = rest || params;
        hash = `#${locationArray[1]}`;
      } else {
        const { page: pageParam, ...rest } = parseQuery(location);

        page = pageParam as string || page;
        params = rest || params;
      }
    } else if (typeof toLocation === 'object' && toLocation !== null) {
      page = toLocation.page || page;
      params = toLocation.params || params;
      hash = toLocation.hash || hash;
    }

    if (page === 'sports') {
      page = 'sportsbook';
    }

    if (hash && !hash.startsWith('#')) {
      hash = `#${hash}`;
    }

    if (window.viewArgs?.allowedPagesList) {
      const isInvalidPage = RSINavigationHandler.isInvalidPage(page);

      if (isInvalidPage) {
        page = getDefaultPage();
        params = {};
        hash = '';
      }
    }

    const paramsString = qs.stringify({ page, ...params }, { addQueryPrefix: true });
    const fullPath = `${paramsString}${hash}`;
    const historyState = isReplace ? LocalHistoryState.Replace : LocalHistoryState.Push;

    logger.debug('[navigate] Calling history with', {
      toLocation: {
        page,
        params,
        hash,
        fullPath,
      },
      historyState,
    });

    window.history[isReplace ? 'replaceState' : 'pushState']({
      page,
      params,
      hash,
      fullPath,
      historyState,
    }, '', fullPath);
    this.updateLocation(isReplace);
  }

  public performNavigation(navigation: string | NavigationPayload): void {
    let isReplace = false;
    let toLocation = navigation;

    if (typeof navigation === 'object') {
      const { historyState, ...rest } = navigation;

      isReplace = historyState === LocalHistoryState.Replace;
      toLocation = rest;
    }

    this.navigate(toLocation, isReplace);
  }

  public getCurrentPage = (): string => this.getState().location.to?.page || getDefaultPage();

  public getPreviousPage = (): string => this.getState().location.from?.page || '';

  // eslint-disable-next-line max-len
  public getCurrentLocation = (): Partial<LocationObject> => this.getState().location.to || { page: getDefaultPage() };

  public getPreviousLocation = (): Partial<LocationObject> => this.getState().location.from || {};

  public back = (fallbackUrl = ''): void => {
    const prevPage = window.location.href;
    window.history.back();

    if (fallbackUrl) {
      setTimeout(() => {
        if (window.location.href === prevPage) {
          this.navigate(fallbackUrl, true);
        }
      }, 200);
    }
  };

  public reload = (): void => {
    window.location.reload();
  };

  public getSportsEventLink(eventId: number, isLive?: boolean): string {
    const formattedEventId = Number(eventId);
    if (!formattedEventId || Number.isNaN(formattedEventId)) {
      throw new TypeError('parameter must be number');
    }

    const eventPath = isLive ? 'event/live/' : 'event/';

    return `?page=sportsbook#${eventPath}${formattedEventId}`;
  }

  private static isInvalidPage(page: string): boolean {
    return !page
      || !window.viewArgs?.allowedPagesList?.includes(page);
  }
}

export default RSINavigationHandler;
