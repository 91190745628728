import { AnyAction } from 'redux';
import { LocationState } from '../../interfaces';
import { LocationActionTypes } from '../actionTypes';

const initialState: LocationState = {
  to: undefined,
  from: undefined,
};

export default (
  state = initialState,
  action: AnyAction = { type: LocationActionTypes.DEFAULT },
) => {
  switch (action.type) {
    case LocationActionTypes.SET_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
