/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
export enum MainViewType {
  Casino = 'CASINO',
  Sportsbook = 'SPORTSBOOK',
}

export enum DefaultPages {
  Sportsbook = 'sportsbook',
  OldSports = 'sports',
  AllGames = 'all-games',
  Landing = 'landing',
}
