import qs from 'qs';
import { DefaultPages, MainViewType } from '../interfaces';
import logger from '../services/logger';

export const getCookie = (cname: string): string => {
  try {
    if (!cname) {
      return '';
    }
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  } catch (err) {
    return '';
  }
  return '';
};

export const shouldIntercept = (href: string): boolean => {
  if (href.indexOf('http:') === 0) {
    logger.error('Insecure href link detected', { href });
  }

  if (href.indexOf('http') === 0) {
    // Is a full URL, so probably external link
    return false;
  }

  if (href.indexOf('?') > -1) {
    return true;
  }

  return false;
};

export const getDefaultPage = (): string => {
  let defaultPage = DefaultPages.Landing;

  // Validate that landing exists
  if (
    !window.viewArgs?.allowedPagesList?.includes(defaultPage)
  ) {
    // if landing doesn't exist, try defaulting to sportsbook
    if (window.viewArgs?.allowedPagesList?.includes(DefaultPages.Sportsbook)) {
      defaultPage = DefaultPages.Sportsbook;
    } else {
      // else default to casino
      defaultPage = DefaultPages.AllGames;
    }
  }

  // for existing users, use last visited page
  const activeUser = getCookie('activeUser');
  if (activeUser) {
    const viewType = getCookie('mainViewType');

    defaultPage = viewType === MainViewType.Casino
      ? DefaultPages.AllGames
      : DefaultPages.Sportsbook;
  }

  return defaultPage;
};

export const findAnchor = (targetElement: Element): Element => {
  try {
    let element = targetElement;
    const maxParents = 3;

    let i = 0;
    while (element && i < maxParents) {
      if (element.tagName !== 'A') {
        element = element.parentElement as Element;
        i += 1;
      } else {
        break;
      }
    }

    return element.tagName === 'A' ? element : targetElement;
  } catch (e) {
    return targetElement;
  }
};

export const parseQuery = (string: string) => qs.parse(string, { ignoreQueryPrefix: true });

export const isObject = (obj: any) => {
  if (typeof obj === 'object' && obj != null) {
    return true;
  }
  return false;
};
