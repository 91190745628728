/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum LocalHistoryState {
  Auto = 'AUTO',
  Push = 'PUSH',
  Replace = 'REPLACE',
}

export interface NavigationPayload {
  page?: string;
  hash?: string;
  historyState?: LocalHistoryState;
  params?: { [key: string]: any };
}
