import { AnyAction, Store, Unsubscribe } from 'redux';
import { InitArgs, LocationState, RootState } from '../interfaces';
import store from '.';

export default class DataStore {
  public store: Store;

  constructor({ initialState }: InitArgs) {
    this.store = store(initialState);
  }

  public dispatch(action: AnyAction): void {
    this.store.dispatch(action);
  }

  public getState(): RootState {
    return this.store.getState();
  }

  public subscribe(callback: Function): Unsubscribe {
    let currentState: LocationState = {};

    const handleChange = () => {
      const nextState: any = this.store.getState().location;

      if (nextState.to.fullPath !== currentState?.to?.fullPath) {
        currentState = nextState;
        callback(nextState);
      }
    };

    const unsubscribe = this.store.subscribe(handleChange);
    handleChange();

    return unsubscribe;
  }
}
